<template>
  <div>
    <div class="top">
      <el-button style=" position: absolute; right: 80px;top: 20px;z-index: 1  ;" type="primary" plain size="mini"
        @click="state = !state">切换显示模式</el-button>

      <!-- 填报按钮 -->
      <div style="position: absolute; right: 25px;top: 60px;z-index: 1  ;">
        <el-popover popper-class="popover-f" style="margin-right: 40px;" placement="bottom" trigger="hover">
          <div class="popover-box">
            <p class="box-title">志愿位置</p>
            <span v-for="h in len" :key="h" class="box-item" @click="chooseCollege(collegeData, h)">
              <span v-if="record.select_code[h - 1] != ''" class="box-item-box"
                style="background: #fbbfbb; border: #fbbfbb;">{{ h }}</span>
              <span v-else class="box-item-box">{{ h }}</span>
            </span>
          </div>

          <el-button size="mini" v-if="record.select_code.indexOf(collegeData.select_code) > -1" slot="reference"
            type="primary">
            志愿{{ record.select_code.indexOf(collegeData.select_code) + 1 }}
          </el-button>
          <el-button v-else slot="reference" size="mini">填报为</el-button>
        </el-popover>
        服从调剂：
        <el-radio-group v-model="tiaoji" style="margin-right: 20px;" @change="getTiaoji">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>

      <el-row type="flex" align="middle">
        <el-col :span="1.8">
          <el-avatar class="school_logo" :size="60" style="margin-top: 10px;margin-right: 20px;"
            :src="collegeData.logo" />
        </el-col>
        <el-col :span="22">
          <p class="row">
            <span style="margin-right: 10px;font-size: 18px;">{{ collegeData.name }}</span>
            <span style="font-size: 18px;margin-right: 20px;">[{{ collegeData.group_name }}]</span>
            <span style="color:#adadad;font-size: 16px;">办学类型：{{ collegeData.nature }}</span>
            <span v-if="collegeData.school_tip" style="color:#adadad;font-size: 16px;">
              | {{ collegeData.school_tip }}
            </span>

          </p>
          <p style="color:#adadad;margin-top: 20px;font-size: 16px;  white-space: nowrap;overflow: hidden;
  text-overflow: ellipsis; ">
            <span>院校代码：<span style="color: #000000;">{{ collegeData.school_id }}</span></span>
            <span style="margin-left: 15px;">2025招生计划： <span style="color: #000000;"> {{ collegeData.plan_num }}</span>
            </span>
            <span style="margin-left: 15px;">2025选科要求：<span style="color: #000000;">{{ collegeData.demand
                }}</span></span>
            <span v-if="collegeData.city_name" style="margin-left: 25px; white-space: nowrap">所在地：
              <span style="color: #000000;"> {{ collegeData.address }}</span>
            </span>
          </p>
        </el-col>
      </el-row>

      <!-- 专业组 -->
      <div class="school_group">
        <el-row type="flex" align="middle" :gutter="10">
          <el-col :span="2">
            <div class="title">
              院校<br> 专业组
            </div>
          </el-col>
          <el-col :span="22" v-if="this.select_code">
            <div :class="{ act: item.select_code === select_code, group_item: true }"
              v-for="(item, i) in CollegeListData" :key="i" @click="chooseGroup(item)">
              <div class="tuijian-1 tuijian" v-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 50">
                <p>[
                  {{ item.title }}
                  ]
                </p>
                <div>难
                </div>
              </div>

              <div class="tuijian-2 tuijian" v-else-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 150">
                <p>[
                  {{ item.title }}
                  ]
                </p>
                <div>冲一冲</div>
              </div>

              <div class="tuijian-3 tuijian" v-else-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 200">
                <p>[
                  {{ item.title }}
                  ]
                </p>
                <div>稳一稳</div>
              </div>
              <div class="tuijian-4 tuijian" v-else-if="(((item.ranking - userRanking) / 1000)
                + 50).toFixed(1) < 300">
                <p>[
                  {{ item.title }}
                  ]
                </p>
                <div>保一保</div>
              </div>
              <div class="tuijian-5 tuijian" v-else>
                <p>[
                  {{ item.title }}
                  ]
                </p>
                <div>暂不推荐</div>
              </div>
            </div>
          </el-col>

        </el-row>
      </div>
    </div>

    <div class="score" v-if="state == true">
      <el-row style="margin-top: 20px" :gutter="20" class="plan_tab1">
        <el-col :span="12">

          <el-table :data="planData" class="tab_plan" height="325"
            :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
            :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
            <el-table-column label="今年招生计划">
              <el-table-column prop="specialty_id" label="代码" width="49px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.specialty_id | interpo }}
                </template>
              </el-table-column>
              <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="remarks" label="专业备注" show-overflow-tooltip align="center" width="60">
              </el-table-column>
              <el-table-column prop="plan_num" label="招生计划" align="center" width="60">
              </el-table-column>
              <el-table-column prop="tuition" label="学费/年" align="center" width="100"> </el-table-column>
              <el-table-column label="操作" align="center" width="80">
                <template slot-scope="scope">
                  <el-button v-if="spList.indexOf(scope.row.id) > -1" @click="chooseSpecialty(scope.row)" size="mini"
                    type="primary">
                    已选择 {{ spList.indexOf(scope.row.id) + 1 }}
                  </el-button>
                  <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
                    填报
                  </el-button>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="12">
          <MojarOld :searchKey='searchKey' :userInfo="userInfo" :oldLine="oldLine" :collegeData="collegeDatas" />
        </el-col>
      </el-row>
    </div>

    <div class="score" v-else style="height: 450px; overflow-y: scroll;">
      <div v-for="(item, i) in planData" :key="i" style="position: relative;">
        <div
          style="font-size: 20px;margin-top: 30px;font-weight: normal;line-height: 22px;letter-spacing: 0em;color: #3A3A3A;">
          {{ item.specialty_name }}
          <span v-if="item.guojia == 1" class="tip" style="font-size: 16px;
font-weight: normal;
line-height: 22px;
letter-spacing: 0em;
border: 1px solid #459DF5;
border-radius: 30px;
padding: 0px 8px;
margin-left: 15px;
color: #459DF5;">特色专业</span>
          <span v-if="item.guojiayiliu == 1" style="font-size: 16px;
font-weight: normal;
line-height: 22px;
letter-spacing: 0em;
border: 1px solid #459DF5;
border-radius: 30px;
padding: 0px 8px;
margin-left: 15px;
color: #459DF5;">国家一流专业</span>

<span v-if="item.shengyiliu == 1" style="font-size: 16px;
font-weight: normal;
line-height: 22px;
letter-spacing: 0em;
border: 1px solid #459DF5;
border-radius: 30px;
padding: 0px 8px;
margin-left: 15px;
color: #459DF5;">省级一流专业</span>

        </div>
        <div
          style="font-size: 16px;margin-top: 8px;font-weight: normal;line-height: 22px;letter-spacing: 0em;color: #3A3A3A;">
          {{ item.remarks }}</div>
        <div style="margin-top: 8px;font-size: 16px;font-weight: normal;line-height: 22px;letter-spacing: 0em;">
          <span style="margin-right: 30px;">招生计划：{{ item.plan_num }} </span>
          <span style="margin-right: 30px;">专业代码：{{ item.specialty_id }}</span>
          <span style="margin-right: 30px;">学费：{{ item.tuition }}</span>
        </div>

        <div style="position: absolute; right: 1px;top:  -5px;">
          <el-button v-if="spList.indexOf(item.id) > -1" @click="chooseSpecialty(item)" size="mini" type="primary">
            已选择 {{ spList.indexOf(item.id) + 1 }}
          </el-button>
          <el-button v-else @click="chooseSpecialty(item)" size="mini">
            填报
          </el-button>
        </div>



        <div class="table">
          <table class="tb1" border="1px">
            <tbody v-if="specialtyScoreGroup[item.specialty_id]">
              <tr>
                <td class="bg_line">年份</td>
                <td class="bg_line">计划数</td>
                <td class="bg_line">最高分</td>
                <td class="bg_line">平均分</td>
                <td class="bg_line">最低分</td>
                <td class="bg_line">最低位次</td>
                <td class="bg_line">换算位次</td>
                <td class="bg_line">比我的位次</td>
              </tr>
              <tr>
                <!--                    年份-->
                <td>2024</td>
                <!--计划数 -->
                <td>{{ specialtyScoreGroup[item.specialty_id].plan_num_1 }}</td>
                <!--  最高分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].max_score_1 }}</td>
                <!--  平均分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].mean_score_1 }}</td>
                <!--  最低分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].min_score_1 }}</td>
                <!--  最低分位次-->
                <td>{{ specialtyScoreGroup[item.specialty_id].ranking_1 }}</td>
                <td v-if="specialtyScoreGroup[item.specialty_id].ranking_1">{{ rankingData.ranking_1 }}</td>
                <td v-else></td>
                <td v-if="specialtyScoreGroup[item.specialty_id].ranking_1">{{
                  specialtyScoreGroup[item.specialty_id].ranking_1 - rankingData.ranking_1 }}</td>
                <td v-else></td>
              </tr>
              <tr>
                <td>2023</td>
                <!--计划数 -->
                <td>{{ specialtyScoreGroup[item.specialty_id].plan_num_2 }}</td>
                <!--  最高分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].max_score_2 }}</td>
                <!--  平均分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].mean_score_2 }}</td>
                <!--  最低分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].min_score_2 }}</td>
                <!--  最低分位次-->
                <td>{{ specialtyScoreGroup[item.specialty_id].ranking_2 }}</td>

                <td v-if="specialtyScoreGroup[item.specialty_id].ranking_2">{{ rankingData.ranking_2 }}</td>
                <td v-else></td>
                <td v-if="specialtyScoreGroup[item.specialty_id].ranking_2">{{
                  specialtyScoreGroup[item.specialty_id].ranking_2 - rankingData.ranking_2 }}</td>
                <td v-else></td>
              </tr>
              <tr>
                <td>2022</td>
                <!--计划数 -->
                <td>{{ specialtyScoreGroup[item.specialty_id].plan_num_3 }}</td>
                <!--  最高分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].max_score_3 }}</td>
                <!--  平均分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].mean_score_3 }}</td>
                <!--  最低分-->
                <td>{{ specialtyScoreGroup[item.specialty_id].min_score_3 }}</td>
                <!--  最低分位次-->
                <td>{{ specialtyScoreGroup[item.specialty_id].ranking_3 }}</td>

                <td v-if="specialtyScoreGroup[item.specialty_id].ranking_3">{{ rankingData.ranking_3 }}</td>
                <td v-else></td>
                <td v-if="specialtyScoreGroup[item.specialty_id].ranking_3">{{
                  specialtyScoreGroup[item.specialty_id].ranking_3 - rankingData.ranking_3 }}</td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import MojarOld from "./MojarOld";
import { mapState } from "vuex";
export default {
  props: ["collegeDatas", 'oldLine', 'subject_id', 'rankingData'],
  name: "",
  components: {
    MojarOld
  },
  data() {
    return {
      planData: [],
      loading: true,
      searchKey: "",
      tiaoji: 1,
      spList: [],
      select_code: 1,
      CollegeListData: [],
      currentOffset: 0,
      windowSize: 4,
      paginationFactor: 305,
      len: 48,
      collegeData: {},
      state: false,
      specialtyScoreGroup: []
    };
  },
  computed: {
    ...mapState(["record", 'userInfo']),
    userRanking() {
      return this.rankingData.ranking * 5 + this.rankingData.ranking_1 * 3 + this.rankingData.ranking_2 * 2
    },
  },
  mounted() {
    this.collegeData = this.collegeDatas
    this.select_code = this.collegeData.select_code
    this.new_batch = this.collegeData.new_batch

    //获取学校所有组
    this.GetCollegeGroup()

    let index = this.record.select_code.indexOf(this.select_code)

    //设置spList
    if (index > -1) {
      this.tiaoji = this.record.table[index].tiaoji
      if (this.record.specialty[index].length > 0) {
        this.spList = this.record.specialty[index].map(v => { return v.id })
      } else {
        this.spList = []
      }
      this.$forceUpdate
    }

    //获取批次

    this.getPlan();
    this.getScoreGruop()
  },

  methods: {
    //搜索院校
    GetCollegeGroup() {
      this.$fecth
        .post("volunteer_demand/GetCollegeGroup", {
          name: this.collegeData.name,
          subject_id: this.subject_id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          this.CollegeListData = res.lists;
        });
    },

    //选择组
    chooseGroup(item) {
      this.select_code = item.select_code
      this.collegeData = item

      let index = this.record.select_code.indexOf(this.select_code)
      //设置spList
      if (index > -1) {
        if (this.record.specialty[index].length > 0) {
          this.spList = this.record.specialty[index].map(v => { return v.id })
        } else {
          this.spList = []
        }

        this.$forceUpdate
      }
      this.getPlan()
      this.getScoreGruop()
    },



    //获取数据
    getPlan() {
      if (this.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer_demand/getPlan", {
            select_code: this.select_code,
            new_batch: this.new_batch,
          })
          .then((res) => {
            this.planData = res;
            this.loading = false;
          });
      }
    },
    getScoreGruop() {
      if (this.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer_demand/get_specialty_score_group", {
            select_code: this.select_code,
            subject: this.userInfo.subject,
            new_batch: this.new_batch,
          })
          .then((res) => {
            this.specialtyScoreGroup = res
          });
      }
    },


    //选择专业
    chooseSpecialty(item) {

      //判断是否选择志愿位置
      let index = this.record.select_code.indexOf(this.select_code);

      if (index > -1) {

        //判断这个专业是否填报过
        let sp_id = this.spList.indexOf(item.id);

        //如果填报过就删除
        if (sp_id > -1) {
          this.record.specialty[index].splice(sp_id, 1)
          this.spList.splice(sp_id, 1);
        } else {
          //判断是否已经选了6个志愿
          let splen = this.spList.length;
          if (splen == 6) {
            this.$alert("每个志愿最多填报6个专业！", "提示", {
              confirmButtonText: "确定",
            });
            return false;
          }

          this.searchKey = item.specialty_name

          this.record.specialty[index].push(item);
          this.spList.push(item.id);
          this.$store.commit('setRecord', this.record)
        }
        this.updateReport()
      } else {
        this.$alert("请先选择院校", "提示", {
          confirmButtonText: "确定",
        }).catch((msg) => {
          console.log(msg);
        });
      }
    },

    updateReport() {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.userInfo.id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
          record: JSON.stringify(this.record)
        })
      this.$store.commit('setRecord', this.record)
    },

    changeAlert(item) {
      item.tiaoji = 1;
      this.$forceUpdate();
    },

    //填报为
    chooseCollege(item, i) {
      item.tiaoji = 1
      // console.log(item,i)
      // return false
      let select_code_id = this.record.select_code.indexOf(item.select_code)

      //如果这个学校填过就删除
      if (select_code_id > -1) {
        this.record.table[select_code_id] = {
          name: "暂无",
        }
        this.record.select_code[select_code_id] = ''
        this.record.specialty[select_code_id] = []
      }

      //添加这个位置的志愿
      this.record.table[i - 1] = item
      this.record.select_code[i - 1] = item.select_code
      this.record.specialty[i - 1] = []

      this.spList = []

      //更新数据流
      this.$store.commit('setRecord', this.record)
      this.$emit('refush', this.record)
      this.$forceUpdate()


      this.updateReport()
    },

    getTiaoji() {
      let index = this.record.select_code.indexOf(this.select_code);
      if (index > -1) {
        this.record.table[index].tiaoji = this.tiaoji
      }
      this.updateReport()
    },
  },
};
</script>

<style scoped lang='less'>
.tb1 {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;

  tbody {
    line-height: 28px;

    .bg_line {
      // background: #f4f7fc;
    }
  }
}

.top {
  background: #CBEAFF;
  padding: 15px 20px;
  position: relative;
}


.row {
  font-size: 25px;
  color: black;
}

.school_group {
  margin-top: 20px;

  .title {
    font-size: 22px;
    text-align: center;
  }

  .group_item {
    display: inline-block;

    .tuijian {
      margin-right: 8px;
      text-align: center;
      border-radius: 4px;
      margin-top: 5px;
      display: inline-block;
      padding: 3px 5px;
      width: 80px;
      vertical-align: bottom;
      background: white;

      p {
        font-size: 14px;
      }

      div {
        display: inline-block;
        font-size: 13px;
        margin-top: 5px;
        border-radius: 3px;
        width: 60px;
        padding: 1px 0;
        color: white;
      }
    }

    .tuijian-1 {
      border: 1px solid #C10008;
      color: #C10008;

      div {
        background: #C10008;
      }
    }

    .tuijian-2 {
      border: 1px solid #F23D4F;
      color: #F23D4F;

      div {
        background: #F23D4F;
      }
    }

    .tuijian-3 {
      border: 1px solid #FF7C33;
      color: #FF7C33;

      div {
        background: #FF7C33;
      }
    }

    .tuijian-4 {
      border: 1px solid #3A9CED;
      color: #3A9CED;

      div {
        background: #3A9CED;
      }
    }

    .tuijian-5 {
      border: 1px solid #FFD813;
      color: #FFD813;

      p {
        color: #707070;
      }

      div {
        color: #707070;
        background: #FFD813;
      }
    }
  }

  .tianbao {
    width: 25px;
    padding: 5px 0;
    display: inline-block;
    line-height: 107%;
    vertical-align: bottom;
    border: 1px solid red;
    color: red;
    display: inline-block;
    border-left: 0;
    border-radius: 0 5px 5px 0;
  }

  .act {
    .tuijian {
      background: #FFE2E5;
      display: inline-block;
    }

  }
}



.info {
  font-size: 14px;
  position: relative;

  span {
    display: inline-block;
  }
}
</style>

<style lang="less">
.tab_plan {

  .el-table td,
  .el-table th {
    padding: 0px;
  }
}

.score {
  padding: 5px 30px 60px;
}
</style>